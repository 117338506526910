/**
 * @module SiteMenu
 * @description Site Menu
 */
import {
  useEffect, useState, useContext, useRef
} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useSiteNavigation } from '@/hooks/useSiteNavigation';
import { useProfile } from '@/hooks/useProfile';
import { stringify } from '@/js/utils/filters';
import { fastly } from '@/js/utils/helpers';
import { FuelContext } from '@/providers/fuel-provider';
import { trackEvent } from '@/js/helpers/tracking';
import { addPageAction } from '@/js/utils/new-relic';
import DynamicButton from '@/blocks/Buttons/DynamicButton';

const YextSnippet = dynamic( () => import( '@/resources/Yext' ) );

/**
   * @function handleClick
   * @description fire off tagular events
   */
const handleClick = ( event ) => {
  const { target } = event;
  const payload = JSON.parse( target.getAttribute( 'data-payload' ) );
  const { text, actionOutcome, position } = payload;

  const data = {
    elementType: 'BUTTON',
    location: 'GLOBAL',
    position,
    text,
    actionOutcome
  };

  trackEvent( { action: 'elementClicked', data, event } );
};

/**
   * @function handleBuyClick
   * @description fire New Relic cart entrance event
   */
const handleBuyClick = ( event ) => {
  addPageAction( 'cartEntrance', { method: 'MASTHEAD' } );
  handleClick( event );
};

/**
 * @function SiteNavigation
 * @description
 */
export const SiteNavigation = ( { ...props } ) => {
  const {
    mobileCallEnabled, mobileCallToggleIcon,
    menus = [
      {
        title: 'Shop',
        links: [
          { href: '/shop/deals', title: 'Deals' },
          { href: '/shop', title: 'Plans' },
          {
            href: '/shop/internet',
            title: 'Internet',
            links: [
              { href: '/shop/internet/fiber-internet/7-gig', title: '7 Gig' },
              { href: '/shop/internet/fiber-internet/5-gig', title: '5 Gig' },
              { href: '/shop/internet/fiber-internet/2-gig', title: '2 Gig' },
              { href: '/shop/internet/fiber-internet/gig', title: '1 Gig' },
              { href: '/shop/internet/fiber-internet/500', title: '500 Mbps' },
              { href: '/discount-programs/affordable-connectivity-program', title: 'Affordable Connectivity' }
            ]
          },
          { href: '/shop/phone', title: 'Phone' },
          { href: '/shop/tv', title: 'TV & Streaming' },
          { href: '/shop/additional-services', title: 'Additional Services' }
        ]
      },
      {
        title: 'Why Frontier',
        links: [
          { href: '/why-frontier', title: 'Why Frontier' },
          { href: '/why-frontier/why-fiber-internet', title: 'Why Fiber' },
          { href: '/why-frontier/why-fiber-internet/fiber-expansion', title: 'Fiber Expansion' },
          { href: '/why-frontier/get-fiber', title: 'Uncable Yourself' }
        ]
      },
      {
        title: 'Support',
        links: [
          { href: '/helpcenter', title: 'Help Center' },
          { href: 'https://videos.frontier.com/', title: 'How-To Videos' },
          { href: '/helpcenter/supportwizard/troubleshoot/sign-in/get-started', title: 'Support Wizard' },
          { href: '/helpcenter/order-ticket-status', title: 'Order/Ticket Status' },
          { href: '/contact-us', title: 'Contact Us' },
          { href: '/resources/movers', title: 'Moving' }
        ]
      }
    ],
    headerLinks = [
      {
        icon: 'icon-magnifying-glass2', href: '/search', title: 'Search', handler: handleClick
      },
      {
        icon: 'icon-offers', href: null, title: 'Buy', handler: handleBuyClick
      }
    ],
    userMenu = {
      links: [
        { href: '/account#/summary', title: 'Account Summary' },
        { href: '/account#/payments/onetime', title: 'My Payments' },
        { href: '/account#/statements/current', title: 'My Bills' },
        { href: '/account#/profile', title: 'My Profile' },
        { href: '/resources/myfrontier-mobile-app', title: 'MyFrontier Mobile App', target: '_blank' }
      ]
    },
    complementaryLinks = [
      { href: '/', title: 'Residential' },
      { href: 'https://business.frontier.com/?kbid=80875', title: 'Small Business' },
      { href: 'https://enterprise.frontier.com/', title: 'Enterprise' },
      { href: 'https://wholesale.frontier.com/home', title: 'Wholesale' },
      { href: '/resources/multifamily', title: 'Multifamily' }
    ]
  } = props;

  const { fetchProfile, logout } = useProfile( );
  const { init } = useSiteNavigation();

  const [ profile, setProfile ] = useState( null );
  const [ loadYext, setLoadYext ] = useState( false );
  const { clickThrough, cartHeavy } = useContext( FuelContext );

  const didInitRef = useRef( false );
  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    fetchProfile( )
      .then( ( data ) => {
        setProfile( data );
      } )
      .catch( ( err ) => {
        console.error( err );
      } )
      .finally( ( ) => {
        // Allow time for profile menu to populate before registering event listeners
        setTimeout( () => {
          if ( didInitRef.current === false ) {
            didInitRef.current = true;
            init();
          }
        }, 500 );
      } );
  }, [ ] ); /* eslint-disable-line react-hooks/exhaustive-deps */

  return <>
    <div className="l-container">

      <div className="site-header-primary">
        <div className="navigation-toggle-wrap">
          <button className="navigation-toggle" type="button" role="button" onClick={ () => setLoadYext( true ) }>
            <span className="navigation-toggle-title screen-reader-text">Open Menu</span>
            <span className="navigation-toggle-icon">
              <span className="icon icon-bar" />
            </span>
          </button>{ /* <!-- .navigation-toggle--> */ }
        </div>


        <div className="site-branding">
          <Link
            href="/"
            className="site-brand"
            onClick={ handleClick }
            data-payload={ stringify( {
              text: 'Frontier Logo',
              position: 'NAVIGATION',
              actionOutcome: 'INTERALLINK'
            } ) }
            rel="home">
            <span className="screen-reader-text">Frontier Communications</span>
            <Image
              src="/site-logo-rebrand.svg"
              alt="Frontier Communications Logo"
              width={ 36 }
              height={ 48 }
              className="site-logo"
              loading="eager"
              loader={ fastly }
            />

          </Link>
        </div>{ /* <!-- .site-branding --> */ }

        <div className="site-sign-in">
            { ( mobileCallEnabled && ! cartHeavy ) &&
              <DynamicButton
                type="phone"
                title="Cart"
                altPhoneNumber="8778376222">
                <>{ mobileCallToggleIcon ? <span className="icon icon-phone-outline" style={ { fontSize: '18px' } } /> : 'Call' }</>
              </DynamicButton>
            }
            { profile ?
              <button
                className="btn navigation-toggle--user-open"
                type="button"
                onClick={ handleClick }
                data-payload={ stringify( {
                  text: 'Sign In',
                  position: 'NAVIGATION',
                  actionOutcome: 'INTERALLINK'
                } ) }
                role="button"
                ><span className="sign-in__name" >{ `Hi, ${ profile.firstName }` }</span>
              </button> :
            <Link href="/login">Sign In</Link>
            }
        </div>{ /* <!-- .site-sign-in --> */ }
      </div>{ /* <!-- .site-header-primary --> */ }

      <div className="navigation-collapse">
        <div id="navigation" className="navigation-area">

          <div className="navigation-area-search">
            <div className="yext-search-container"></div>
            { loadYext && <YextSnippet /> }
          </div>{ /* <!-- .navigation-area-search --> */ }

          <div className="navigation-area-primary">
            <nav className="navigation site-navigation" role="navigation" aria-label="Site Navigation">
              <ul className="site-menu menu">
                { menus.map( ( menu, index ) => (
                    <li key={ index } className={ `menu-item ${ menu.links.length ? 'menu-item-has-children' : '' }` }>

                    { menu.links.length ?
                      <>
                        <button
                          className="btn"
                          type="button"
                          onClick={ handleClick }
                          data-payload={ stringify( {
                            text: menu.title,
                            actionOutcome: 'INTERALLINK'
                          } ) }
                          role="button">{ menu.title }</button>
                      </> :
                      <>
                        <Link
                          href={ menu.href }
                          target={ menu.target }
                          onClick={ handleClick }
                          data-payload={ stringify( {
                            text: menu.title,
                            position: 'NAVIGATION',
                            actionOutcome: 'INTERALLINK'
                          } ) }>
                          { menu.title }
                        </Link>
                      </>
                    }


                      <div className="submenu-wrap">
                        <ul className="submenu menu">
                          { menu.links.map( ( link, index1 ) => (
                              <li key={ index1 }>
                                <Link
                                  href={ link.href }
                                  target={ link.target }
                                  onClick={ handleClick }
                                  data-payload={ stringify( {
                                    text: link.title,
                                    position: 'NAVIGATION',
                                    actionOutcome: 'INTERALLINK'
                                  } ) }>
                                  { link.title }
                                </Link>

                                { link.links &&
                                  <ul>
                                    { link.links.map( ( link1, index2 ) => (
                                        <li key={ index2 }>
                                          <Link
                                            href={ link1.href }
                                            target={ link1.target }
                                            onClick={ handleClick }
                                            data-payload={ stringify( {
                                              text: link1.title,
                                              position: 'NAVIGATION',
                                              actionOutcome: 'INTERALLINK'
                                            } ) }>
                                            { link1.title }
                                          </Link>
                                        </li>
                                    ) ) }
                                  </ul>
                                }
                              </li>
                          ) ) } { /* <!-- .menu-item --> */ }
                        </ul>
                      </div>
                    </li>
                ) ) }

                <li>
                  <Link
                    href="/shop/deals"
                    onClick={ handleClick }
                    data-tag="new"
                    data-payload={ stringify( {
                      text: 'Deals',
                      position: 'NAVIGATION',
                      actionOutcome: 'INTERALLINK'
                    } ) }>
                      Deals
                  </Link>
                </li>
              </ul>
            </nav>{ /* <!-- .site-navigation --> */ }
          </div>{ /* <!-- .navigation-area-primary --> */ }

          <div className="navigation-area-secondary">
            <ul className="menu header-menu">
              { headerLinks.map( ( link, index ) => (
                <li key={ index }>
                  <Link
                    href={ link.href || clickThrough }
                    target={ link.target }
                    onClick={ link.handler }
                    data-payload={ stringify( {
                      text: link.title,
                      position: 'NAVIGATION',
                      actionOutcome: 'INTERALLINK'
                    } ) }>

                    <span className="navigation-link-text display-none-md">{ link.title }</span> <span className={ `icon ${ link.icon }` } />

                  </Link>
                </li>
              ) ) }

              <li className="menu-item menu-item-has-children">
                { profile ?
                  <>
                    <button
                      className="btn"
                      type="button"
                      onClick={ handleClick }
                      data-payload={ stringify( {
                        text: 'Sign In',
                        position: 'NAVIGATION',
                        actionOutcome: 'INTERALLINK'
                      } ) }
                      role="button"><span className="sign-in__name" >{ `Hi, ${ profile.firstName }` }</span></button>
                    </> :
                    <>
                      <Link
                        href="/login"
                        onClick={ handleClick }
                        data-payload={ stringify( {
                          text: 'Sign In',
                          position: 'NAVIGATION',
                          actionOutcome: 'INTERALLINK'
                        } ) }>
                        Sign In/Register
                      </Link>
                    </>
                }

                  <div className="submenu-wrap">
                    <ul className="submenu menu">
                    { profile ?
                      <>
                        <li className="sign-in__my-account">
                          <div className="submenu-header">
                              <div className="submenu-title">My Account</div>
                          </div>
                        </li>
                        { userMenu.links.map( ( link, index ) => (
                            <li key={ index }>
                              <Link
                                href={ link.href }
                                target={ link.target }
                                onClick={ handleClick }
                                data-payload={ stringify( {
                                  position: 'USER NAVIGATION',
                                  text: link.title,
                                  actionOutcome: 'INTERALLINK'
                                } ) }>
                                { link.title }
                              </Link>
                            </li>
                        ) ) }
                        <li><button className="sign-in__logout" type="button" role="button" onClick={ () => logout() }>Sign Out</button></li>
                      </> :
                      <>
                        <li><Link href="/">Sign In</Link></li>
                        <li><Link href="/api/logout">Register</Link></li>
                      </>
                    }
                    </ul>
                  </div>
              </li>
            </ul>
          </div>{ /* <!-- .navigation-area-secondary --> */ }

          <div className="navigation-area-tertiary">
            <ul className="menu complementary-menu complementary-menu--secondary">
              <li>
                <Link
                  href={ clickThrough }
                  onClick={ handleBuyClick }
                  data-payload={ stringify( {
                    position: 'MASTHEAD',
                    text: 'Buy',
                    actionOutcome: 'INTERALLINK'
                  } ) }>
                  Buy
                </Link>
              </li>
            </ul>
          </div>{ /* <!-- .navigation-area-tertiary --> */ }

          <div className="navigation-area-quaternary">
            <ul className="menu complementary-menu">
              { complementaryLinks.map( ( link, index ) => (
                  <li key={ index } className={ index === 0 ? '--active' : '' }>
                    <Link
                      href={ link.href }
                      target={ link.target }
                      onClick={ handleClick }
                      data-payload={ stringify( {
                        position: 'MASTHEAD',
                        text: link.title,
                        actionOutcome: 'INTERALLINK'
                      } ) }>
                      { link.title }
                    </Link>
                  </li>
              ) ) }
            </ul>
          </div>{ /* <!-- .navigation-area-quaternary --> */ }
        </div>{ /* <!-- .navigation-area --> */ }
      </div>{ /* <!-- .navigation-collapse --> */ }

      { profile &&
        <div className="navigation-collapse--secondary">
          <nav className="navigation user-navigation" role="navigation" aria-label="User Navigation">
            <ul className="user-menu menu">
              <li className="menu-item menu-item-has-children">
                <div className="submenu-header">
                  <div className="submenu-title">My Account</div>

                  <button className="navigation-toggle--user-close" type="button" role="button">
                    <span className="navigation-toggle-title screen-reader-text">Close Menu</span>
                    <span className="icon icon-close2" />
                  </button>{ /* <!-- .navigation-toggle-close --> */ }
                </div>

                  <div className="submenu-wrap">
                    <ul className="submenu menu">
                      { userMenu.links.map( ( link, index ) => (
                          <li key={ index }>
                            <Link
                              href={ link.href }
                              target={ link.target }
                              onClick={ handleClick }
                              data-payload={ stringify( {
                                position: 'USER NAVIGATION',
                                text: link.title,
                                actionOutcome: 'INTERALLINK'
                              } ) }>
                              { link.title }
                            </Link>
                          </li>
                      ) ) }
                      <li><button className="sign-in__logout" type="button" role="button" onClick={ () => logout() }>Sign Out</button></li>
                    </ul>
                  </div>
              </li>
            </ul>{ /* <!-- .user-menu --> */ }
          </nav>{ /* <!-- .user-navigation --> */ }
        </div>
      } { /* <!-- .navigation-collapse--secondary --> */ }
    </div>
  </>;
};

SiteNavigation.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape( {
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape( {
          href: PropTypes.string,
          title: PropTypes.string,
          target: PropTypes.string
        } )
      )
    } )
  ),
  headerLinks: PropTypes.arrayOf(
    PropTypes.shape( {
      href: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string
    } )
  ),
  userMenu: PropTypes.shape( {
    links: PropTypes.arrayOf(
      PropTypes.shape( {
        href: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.string
      } )
    )
  } ),
  complementaryLinks: PropTypes.arrayOf(
    PropTypes.shape( {
      href: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string
    } )
  )
};

export default SiteNavigation;
